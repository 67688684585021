.navbar {
	text-transform: uppercase;
	&__navbar-user-container {
		padding: 20px 5%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $purple;
		letter-spacing: 2px;
	}
	&__logo-container {
		a {
			&:focus {
				img {

					border: 1px solid black;
				}
			}
		}
	}
	&__logo {
		width: 300px;
	}
	&__navbar-who {
		text-transform: initial;
		margin-right: 15px;
	}
	&__navbar-user-nav {
		display: flex;
		:nth-child(2) {
			border-right: 2px solid;
		}
	}
	&__navbar-user-link {
		display: inline-block;
		color: $purple;
		padding-left: 15px;
		padding-right: 15px;
		font-weight: 500;
		&:hover {
			text-decoration: underline;
		}
	}
	&__red-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $pink;
		height: 70px;
		color: $red;
		font-weight: 700;
		font-size: 16px;
	}
	&__link {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 3%;
		border-right: 1px solid $white;
		border-left: 1px solid $white;
		font-weight: 700;
		&:hover {
			cursor: pointer;
		}
	}
	&__button {
		color: $red;
		background-color: $pink;
		text-transform: uppercase;
		border-bottom: 0px;
	}
	&__blue-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		color: $white;
		background-color: $purple;
	}
}

@media only screen and (max-width: 740px) {
	.navbar {
		&__navbar-user-container {
			display: none;
		}
		&__navbar-container {
			display: none;
		}
		&__navbar-who {
			margin-top: 5px;
		}
	}
}

.modal {
	&__show-modal {
		display: none;
		padding: 20px 5%;
	}
	&__show-modal-logo {
		width: 250px;
	}
	&__header-container {
		display: flex;
		justify-content: space-between;
		padding: 20px 5%;
	}
	&__close-button {
		font-size: 70px;
	}
	&__happy-cup {
		position: absolute;
		bottom: 100px;
		left: 10%;
		z-index: 1;
		width: 60%;
	}
}

@media only screen and (max-width: 740px) {
	.modal {
		&__navbar-user-container {
			.navbar__navbar-user-container {
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
			}
			.navbar__logo-container {
				display: none;
	
			}
			.navbar__navbar-user-link-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 70px;				
			}
			.navbar__navbar-user-nav {
				font-size: 15px;
				a {
					color: $red !important;
					text-decoration: none;
					background-color: white;
					border-radius: 20px;
					padding: 5px 10px;
					border: none;
					margin-right: 10px;
				}
				p {
					color: white !important;
					text-transform: lowercase;
				}
			}
		}
		&__show-modal {
			display: flex;
			justify-content: space-between;
		}
		&__burger-ico {
			font-size: 40px;
		}
		&__navbar-modal {
			color: white;
			text-transform: uppercase;
			font-size: 16px;
			.navbar__blue-nav {
				flex-direction: column;
				background-color: $white;
				height: 150px;
				.navbar__link {
					background-color: $purple;
					width: 100%;
					height: 70px;
					margin-top: 3px;
					padding-left: 50px;
					font-weight: 700;
				}
			}
			.navbar__red-nav {
				flex-direction: column;
				background-color: $white;
				height: 150px;
				.navbar__link {
					background-color: $purple;
					color: white;
					width: 100%;
					height: 70px;
					margin-top: 3px;
					padding-left: 50px;
					font-weight: 700;
				}
			}
		}
	}
}
