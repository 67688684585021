$yellow: #ffb800;
$purple: #9129ff;
$lightPurple: #E8D9FF;
$red: #ff224c;
$pink: #ffdce2;
$white: #ffffff;

@font-face {
	font-family: "Chango";
	src: url("../assets//font/Chango-Regular.ttf");
}

@font-face {
	font-family: "DM-Sans";
	src: url("../assets//font/DMSans-VariableFont_opsz\,wght.ttf");
}
// Utilisation : @include font(family, weight)
@mixin font($family, $weight: normal) {
	font-family: $family;
	font-weight: $weight;
  }

@import "./parent.scss";
@import "./young.scss";
@import "./relai.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./about.scss";
@import "./notfound.scss";

//TODO Animation d'ouverture des modals (provisoire, ou pas)
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}
.focused {
	a {
		p {
			&:focus {
				border: 1px solid black;
			}
		}
		&:focus {
			border: 1px solid black;
		}
	}
	&:focus {
		border: 1px solid black;
	}
}
/* RESET CSS */
/* Box sizing rules */
html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
figure,
hr,
p {
	margin: 0;
	// Par default toute les écritures de l'app sont en DM
	@include font(DM-Sans, 400)
}

/* Set core body defaults */
body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.6;
}

/* Remove spacing around display elements */
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}

/* Remove blockquote indentation */
blockquote {
	margin: 0;
}

/* Reset form elements and button styles */
button,
input,
select,
textarea {
	font: inherit;
	margin: 0;
}

/* Remove the inheritance of text transform in Firefox */
button,
select {
	text-transform: none;
}

/* Reset button appearance */
button {
	overflow: visible;
}

/* Remove the focus outline for some elements */
a,
button {
	outline: none;
}

/* Reset anchor styles */
a {
	text-decoration: none;
	color: inherit;
}

/* Remove blue highlight border in Chrome and Firefox */
a,
area,
button,
input,
label,
select,
textarea {
	-webkit-tap-highlight-color: transparent;
}

/* Responsive images */
img {
	max-width: 100%;
	height: auto;
}
/*END RESET CSS*/
