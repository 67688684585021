.about {
    padding: 50px 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    &__text-container {
        width: 65%;
        margin-right: 5%;
        line-height: 1.4;
    }
    &__title {
        font-size: 36px;
        color: $purple;
        line-height: 48px;
        margin-bottom: 30px;
    }
    &__text {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__img-container {
        width: 30%;
    }
    &__img {
        border-radius: 10px;
        box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.15);
    }
    &__button {
        display: inline-block;
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        background-color: $red;
        color: $white;
    }
    &__right-button {
        margin-left: 10px;
    }
    &__cards-container {
        width: 60%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
    }
    &__card-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 20px;
        .full-width {
            width: 100%;
        }
        .half-width {
            width: 50%;
        }
        .third-width {
            width: 30%;
        }
        .two-third-width {
            width: 70%;
        }
    }
    &__card-container {
        .light-card {
            background-color: $lightPurple;
            color: $purple;
        }
        .dark-card {
            background-color: $purple;
            color: $white
        }   
        .left-card {
            margin-right: 10px;
        }
        .right-card {
            margin-left: 10px;
        }      
    }
    &__card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        height: 100%;
        text-align: center;
        padding: 20px;
        border-radius: 10px;
    }
    &__big-number {
        font-size: 40px;
        font-weight: bold;
    }
    &__illu-container {
        width: 30%;
        margin: 0 auto;
        align-self: end;
        margin-bottom: -60px;
    }
}

@media only screen and (max-width: 750px) {
    .about {
        padding: 0;
        margin-bottom: 30px;
        flex-direction: column;
        &__text-container {
            width: 100%;
            padding: 15px 45px;
            margin: 0;
            max-width: none;
        }
        &__img-container {
            width: 100%;
            max-width: none;
        }
        &__img {
            border-radius: 0;
            box-shadow: none;
        }
        &__right-button {
            margin-left: 0;
        }
        &__cards-container {
            width: 80%;
            margin-top: 20px;
        }
        &__card-row {
            margin-bottom: 0;
            .half-width {
                width: 100%;
            }
            .third-width {
                width: 100%;
            }
            .two-third-width {
                width: 100%;
            }
        }
        &__card-container { 
            margin-bottom: 20px;
            .left-card {
                margin-right: 0;
            }
            .right-card {
                margin-left: 0;
            }      
        }
        &__illu-container {
            width: 50%;
            margin-bottom: 0;
        }
    }
}

.about-red {
    background-color: $pink;
    color: $red;
    font-size: 36px;
    line-height: 48px;
    padding: 60px 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &__text {
       width: 70%;
    }
}

@media only screen and (max-width: 750px) {
    .about-red {
        font-size: 24px;
        line-height: 30px;
        &__text {
            width: 100%;
         }
    }
}
