.whiteBook {
    padding: 50px;
    background: linear-gradient(235deg, $lightPurple 0%, $purple 60%);
    display: flex;
    justify-content: center;
    align-items: left;
    color: $white;
    &__left-container {
        width: 60%;
        margin-right: 5%;
        padding-left: 5%;
    }
    &__right-container {
        width: 40%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
    }
    &__title {
        font-size: 24px;
        line-height: 25px;
        text-align: left;
        margin-bottom: 25px;
    }
    &__text {
        font-size: 16px;
        margin-bottom: 15px;
    }
    &__img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
    }
    &__img {
        width: 150px;
    }
    &__download-button {
        width: fit-content;
        margin: 0 auto;
        background-color: $white;
        color: $red;
        padding: 8px 23px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
    }
    &__modal-content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    &__modal-title {
        color: white;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
    }
    &__form {
        display: flex;
        flex-direction: column;
        width: 250px;
    }
    &__modal-text {
        color: white;
        font-size: 16px;
        text-align: center;
        font-style: italic;
        margin-top: 30px;
    }
    &__email-input {
        margin-bottom: 20px;
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
    }
    &__form-button {
        padding: 5px 10px;
        color: $red;
        border: none;
        border-radius: 20px;
        background-color: $white;
        cursor: pointer;
    }
}

@media only screen and (max-width: 750px) {
    .whiteBook {
        flex-direction: column;
        background: linear-gradient(235deg, $lightPurple 0%, $purple 50%);
        &__left-container {
            width: 100%;
            padding: 0;
        }
        &__right-container {
            width: 100%;
        }
        &__modal-content-container {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.atelier {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &__text-container {
        width: 50%;
        margin-right: 5%;
        color: $purple;
        max-width: 500px;
    }
    &__title {
        font-size: 24px;
    }
    &__button {
        display: inline-block;
        margin-top: 20px;
        padding: 5px 15px;
        border: none;
        border-radius: 20px;
        background-color: $red;
        color: $white;
        cursor: pointer;
    }
    &__text {
        font-size: 16px;
        margin-top: 20px;
    }
    &__img-container {
        width: 50%;
        max-width: 700px;
    }
    &__img {
        border-radius: 10px;
        box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (max-width: 750px) {
    .atelier {
        padding: 0;
        margin-bottom: 30px;
        flex-direction: column;
        &__text-container {
            width: 100%;
            padding: 45px;
            margin: 0;
            max-width: none;
        }
        &__img-container {
            width: 100%;
            max-width: none;
        }
        &__img {
            border-radius: 0;
            box-shadow: none;
        }
    }
}