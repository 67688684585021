.homeQuestion {
    &--parent {
        background-color: red !important;
    }
}

.swapArticle {
    .letter {
        min-height: 550px;
    }
    display: flex;
    padding: 0 50px;
    justify-content: center;
    background: linear-gradient(135deg, $white 2.1%, $lightPurple 70.67%);
    &__aside-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5%;
    }
    &__aside-desc-container {
        padding-top: 100px;
    }
    &__aside-title {
        font-size: 40px;
        line-height: 50px;
        color: $purple;
    }

    &__aside-button-ico {
        margin-bottom: -3px;
        margin-left: 3px;
    }
    &__aside-logo-container-desktop {
        display: flex;
        justify-content: center;
    }
    &__aside-logo-desktop {
        width: 230px;
        height: fit-content;
    }

    &__aside-button {
        cursor: pointer;
        border: 1px solid $white;
        background-color: $white;
        margin-top: 30px;
        color: $red;
        padding: 5px 10px;
        border-radius: 20px;
    }

    &__aside-description {
        width: 70%;
        color: $purple;
        margin-top: 30px;
    }
    &__aside-logo {
        margin-left: 5%;
    }
    &__aside-title-container {
        display: flex;
        flex-direction: row;
    }
    &__card-container {
        z-index: 2;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        max-width: 550px;
        min-height: 300px;
        padding: 24px;
        position: relative;
        width: 80%;
        border-radius: 20px;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    &__tag-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    &__tag {
        padding: 5px 10px;
        margin-left: 10px;
        border: 1px solid $purple;
        background-color: $white;
        color: $purple;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
            background-color: $purple;
            color: $white;
        }
    }

    &__description-title {
        color: $purple;
        font-size: 20px;
        @include font(Chango, 400);
        margin-top: 10px;
        text-align: left;
        padding: 0 20px;
    }

    &__description {
        margin-top: 10px;
        color: $purple;
        text-align: center;
        padding: 0 20px;
        p {
            span {
                color: $purple !important;
                font-size: 16px !important;
                @include font(DM-Sans !important, 400);
                strong {
                    font-weight: normal !important;
                }                
            }
        }
    }
    &__row-button-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 3%;
    }
    &__button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            p {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &__cross-ico {
        width: 25px;
        margin-bottom: 10px;
    }
    &__play-ico {
        width: 15px;
        margin-bottom: 10px;
    }

    &__next-button {
        color: $red;
    }

    &__mobile-button {
        display: none;
    }
    &__head-mobile {
        display: none;
    }
}

@media only screen and (max-width: 740px) {
    .swapArticle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        &__aside-container {
            display: none;
        }
        &__head-mobile {
            display: flex;
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;
            padding-left: 20px;
        }
        &__head-title {
            font-size: 28px;
            line-height: 28px;
            color: $purple;
            margin-right: 50px;
        }
        &__mobile-button {
            display: block;
            background-color: $red;
            color: $white;
            border: 1px solid $white;
            border-radius: 20px;
            margin-top: 40px;
            padding: 5px 10px;
            margin-bottom: 30px;
        }
    }
}

.allArticles {
    &__articles {
        margin-bottom: 20px;
    }
    .filter {
        padding-left: 10%;
        padding-right: 5%;
        &__button--inactif {
            color: $purple;
            border-color: $purple;
            text-shadow: none;
        }
        &__button--actif {
            background-color: $purple;
            color: $white;
        }
        &__title {
            color: $purple;
            font-weight: bold;
            text-shadow: none;
        }
    }
    &__une-container {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 30px;
        .articleContainer {
            display: flex;
            justify-content: center;
            margin: auto;
            flex-wrap: wrap;
            .article {
                width: 40%;
            }
        }
    }
    &__first {
        color: $purple;
        font-size: 30px;
        text-align: center;
        margin-bottom: 20px;
    }
    &__last {
        color: $purple;
        font-size: 30px;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 550px) {
    .allArticles {
        &__une-container {
            .articleContainer {
                .article {
                    width: 100%;
                }
            }
        }
    }
}

.articleContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-left: 10%;
    margin-right: 10%;
}
@media only screen and (max-width: 1200px) {
    .articleContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 800px) {
    .articleContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 550px) {
    .articleContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
.article {
    margin-bottom: 5%;
    cursor: pointer;
    &:hover {
        p {
            text-decoration: underline;
        }
    }
    &__header {
        z-index: 2;
        margin-bottom: -35px;
        border-radius: 10px;
    }
    &__title-container {
        width: 80%;
    }
    &__title {
        z-index: 1;
        @include font(Chango, 400);
        color: $purple;
        background-color: $white;
        display: inline-block;
        line-height: 20px;
        margin-left: 20px;
    }

    &__categories {
        display: inline-block;
        margin-left: 5px;
        border-radius: 20px;
        border: 1px solid $purple;
        font-size: 12px;
        color: $purple;
        padding: 3px 10px;

    }
    &__content-container {
        margin-top: 10px;
    }
}

.specificArticle {
    margin-top: 3%;

    &__header {
        display: block;
        width: 50%;
        margin: auto;
        border-radius: 10px;
    }
    &__title-container {
        display: flex;
        justify-content: center;
    }
    &__title {
        margin-top: 30px;
        width: 35%;
        line-height: normal;
        height: fit-content;
        display: inline-block;
        text-align: center;
        color: $purple;
        background-color: $white;
        @include font(Chango, 400);
    }

    &__container-categories {
        margin-top: 1%;
        margin-bottom: 1%;
        text-align: center;
    }

    &__categories {
        display: inline-block;
        margin-left: 5px;
        border-radius: 20px;
        border: 1px solid $purple;
        font-size: 12px;
        color: $purple;
        padding: 3px 10px;
    }

    &__content {
        width: 50%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        p {
            span {
                @include font(DM-Sans !important, 400);
            }
        }
    }

    &__button {
        display: block;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        
        width: 250px;
        color: #ffffff;
        padding: 5px 0px;
        background-color: #ff224c;
        border-radius: 20px;
        border: none;
        cursor: pointer;
    }
}

@media only screen and (max-width: 750px) {
    .specificArticle {
        padding: 20px;
        &__header {
            width: 90%;
        }
        &__title {
            width: 100%;
            margin-bottom: 15px;
            margin-left: 15px;
            margin-right: 15px;
        }
        &__container-categories {
            margin-left: 0;
            margin-bottom: 25px;
        }
        &__content {
            width: 90%;
        }
        &__categories {
            margin-left: 0;
            margin-right: 5px;
        }
    }
}

.ressources {
    &__title {
        font-size: 40px;
        color: $purple;
        margin-bottom: 20px;
        text-align: left;
        padding: 0 10%;
    }
    margin-top: 25px;
    margin-bottom: 50px;
    &__grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-rows: repeat(2, 1fr);
    }
    &__top-line {
        grid-column-start: 2;
        grid-row-start: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__bot-line {
        grid-column-start: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__square {
        display: flex;
        width: 430px;
        height: 150px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid $red;
        border-radius: 10px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
            border: 2px solid $red;
        }
        p {
            text-align: center;
            text-decoration: underline;
            color: $purple;
            font-weight: 500;
        }
    }
}
@media only screen and (max-width: 750px) {
    .ressources {
        &__square {
            width: 150px;
            height: 150px;
        }
    }
}

.headerParent {
    background-image: url("../assets/images/background-purple.png");
    background-repeat: no-repeat;
	background-position: top right;
	background-size: 400px;
    padding: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &__title-container {
        max-width: 700px;
    }
    &__title1 {
        color: $purple;
        font-size: 26px;
        @include font(Chango, 400);
        display: inline-block;
        background-color: white;
    }
    &__title2 {
        color: $lightPurple;
        display: inline-block;
        font-size: 26px;
        @include font(Chango, 400);
        background-color: white;

    }
    &__left-container {
        width: 30%;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    &__left-container-text {
        color: $purple;
        margin-top: 30px;
        font-weight: bold;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    &__left-container-mobile {
        display: none;
    }
    &__video-container {
        max-width: 600px;
        width: 60%;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        background-color: $white;
    }
    &__iframe {
        aspect-ratio: 16 / 9;
        border-radius: 10px 10px 0px 0px;
    }
    &__sub-video-container {
        padding: 20px 20px 20px 20px;
    }
    &__tag-container {
        display: flex;
        flex-direction: row;
    }
    &__tag {
        font-size: 12px;
        padding: 5px 10px;
        margin-right: 10px;
        border: 1px solid $purple;
        border-radius: 20px;
        color: $purple;
        cursor: pointer;
        background-color: $white;
        &:hover {
            background-color: $purple;
            color: $white;
        }
    }
    &__video-title {
        color: $purple;
        @include font(Chango, 400);
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__video-desc {
        color: $purple;
        font-size: 14px;
    }
}

@media only screen and (max-width: 750px) {
    .headerParent {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__left-container {
            display: none;
        }
        &__left-container-mobile {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        &__video-container {
            width: 100%;
        }
        &__title-container {
            margin-bottom: 20px;
        }
        &__title1 {
            font-size: 16px;
        }
        &__title2 {
            font-size: 16px;
        }
    }

}