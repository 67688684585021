.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $purple;
    margin: 50px 0;
    padding: 0 20px;
    h1 {
        font-weight: bold;
        font-size: 40px;
    }
    p {
        font-size: 20px;
        margin-top: 20px;        
    }
}