.faqlist {
	padding-top: 3%;
	padding-bottom: 3%;

	&__title {
		font-size: 50px;
		color: $white;
		text-align: center;
	}

	&__showMore-container {
		display: flex;
		justify-content: center;
		margin-top: 5%;
	}

	&__showMore-button {
		border: 1px solid $white;
		color: $white;
		margin-bottom: 20px;
		background-color: transparent;
		border-radius: 20px;
		padding: 8px 23px;
		cursor: pointer;

		&:hover {
			transition: all ease 0.5s;
			background-color: white;
			color: $red;
		}
	}

	&__user-info-container {
		margin-bottom: 0;
		font-weight: 700;
	}
	&__faq-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: $red;
	}
	&__question-container {
		background-color: $white;
		border-radius: 10px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 60%;
		padding: 20px 20px 20px 30px;
	}

	&__question {
		font-weight: 700;
	}
	&__answer-container {
		background-color: $white;
		border-radius: 10px;
		margin-bottom: 15px;
		width: 60%;
		padding: 20px 30px 20px 30px;
		color: $red;
	}
	&__triangle {
		display: inline;
		margin-bottom: 30px;
		margin-top: -50px;
	}
	&__answer-text {
		margin-top: -25px;
		a {
			text-decoration: underline;
		}
	}

	&__button-container {
		margin-bottom: -20px;
		position: relative;
		text-align: center;
		bottom: -20px;
	}
	&__chevron {
		transition: ease 0.5s;
		min-width: 16px;
		&.actif {
			transition: ease 0.5s;
			transform: rotate(180deg);
		}
	}
	&__filter-container {
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}
	&__dropdown-button {
		background-color: $red;
		border-radius: 50%;
		border: none;
		text-align: center;
		font-size: 30px;
		width: 50px;
		color: white;
		font-weight: bold;
		transition: transform 0.5s ease;
		&--top {
			transform: rotate(180deg);
		}
	}
}

@media only screen and (max-width: 740px) {
	.faqlist {
		&__filter-container {
			width: 90%;
		}
		&__question-container {
			width: 90%;
		}
		&__answer-container {
			width: 90%;
		}
	}
}

.ico {
	padding-top: 5px;
}

.homeQuestion {
	background-color: $yellow;
	padding: 5% 10%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&__title-container {
		color: $white;
		width: 40%;
		margin-right: 5%;
	}
	&__title-line1 {
		font-weight: normal;
		font-size: 40px;
	}
	&__title-line2 {
		font-weight: bold;
		font-size: 40px;
		margin-top: -25px;
	}

	&__question-container {
		width: 60%;
	}

	&__goutte-img {
		width: 50px;
		margin-left: 20px;
	}

	&__button-container {
		display: flex;
		flex-direction: column;
		font-size: 13px;
	}

	&__showMore-button {
		color: $red;
		border-radius: 20px;
		border: none;
		margin-bottom: 10px;
		margin-top: 30px;
		padding: 5px 15px;
		max-width: 250px;
		background-color: white;
		cursor: pointer;
		display: inline-block;
	}

	&__send-question-button {
		color: $white;
		border: none;
		background-color: $red;
		border-radius: 20px;
		padding: 5px 15px;
		max-width: 195px;
		cursor: pointer;
	}

	&__question-top {
		background-color: $white;
		border-radius: 10px;
		padding: 20px 30px;
		color: $red;
		p {
			font-weight: 700;
		}
	}

	&__question-bot {
		background-color: $white;
		border-radius: 10px;
		padding: 20px 30px;
		color: $red;
		margin-top: -15px;
		a {
			text-decoration: underline;
		}
	}

	&__goutte-row {
		display: flex;
		justify-content: right;
	}

	&__goutte-container {
		margin-right: 20px;
		margin-bottom: -25px;
		background-color: white;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	&__triangle {
		margin-top: -20px;
		color: $red;
		margin-left: 20px;
		font-size: 30px;
	}

	&__allFaq-button-container {
		display: none;
	}
}

@media only screen and (max-width: 740px) {
	.homeQuestion {
		flex-direction: column;
		padding: 5% 4%;

		&__goutte-img {
			display: none;
		}

		&__question-container {
			width: 100%;
		}

		&__button-container {
			display: none;
		}

		&__allFaq-button-container {
			display: block;
			margin-top: 10px;
		}

		&__allFaq-button {
			padding: 5px 0px;
			border: 1px solid $white;
			text-align: center;
			background-color: transparent;
			width: 100%;
			color: white;
			border-radius: 20px;
		}

		&__title-container {
			width: 100%;
			margin-left: 50px;
		}
	}
}

.tiktok {
	display: flex;
	margin-top: 10%;
	margin-bottom: 50px;
	&__left-container {
		margin-top: 10%;
		color: $purple;
		font-size: 30px;
		margin-bottom: 40px;
		width: 60%;
	}
	&__title-container {
		width: 80%;
		padding-left: 10%;
	}
	&__title {
		font-weight: normal;
		line-height: 45px;
	}

	&__subtitle {
		font-size: 18px;
		margin-bottom: 50px;
		margin-top: 40px;
	}
	&__tiktok-container {
		width: 40%;
		display: flex;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&__img-container {
		max-width: 400px;
	}
}
@media only screen and (max-width: 740px) {
	.tiktok {
		display: block;
		padding: 0 20px;
		&__left-container {
			width: 100%;
		}
		&__title-container {
			width: 100%;
		}
		&__subtitle {
			display: none;
		}
		&__tiktok-container {
			display: block;
			width: 100%;
		}
		.home-reseaux {
			&__right-container {
				width: 100%;
				margin-left: 0;
			}
		}
	}
}

@media only screen and (min-width: 740px) {
	.homeYoung {
		&__send-question-container {
			display: none;
		}
	}
}

.swapVideo {
	display: flex;
	padding: 3% 5%;
	justify-content: center;
	background: linear-gradient(52deg, #9129ff 26.42%, #ff224c 61.68%);
	&__card-container {
		margin-bottom: -10%;
		width: 60%;
		background-color: $white;
		border-radius: 10px;
		max-width: 700px;
	}
	&__title {
		color: $white;
		font-size: 40px;
		font-weight: normal;
		line-height: 40px;
	}
	&__text {
		width: 70%;
		margin-bottom: 50px;
		margin-top: 25px;
		color: $white;
		font-size: 16px;
	}
	&__aside-container {
		display: flex;
		flex-direction: column;
		margin-top: 10%;
	}
	&__aside-button {
		text-align: left;
		margin-bottom: 5%;
		border-radius: 20px;
		background-color: $white;
		border: none;
		color: $red;
		padding: 5px 15px;
		height: 38px;
		cursor: pointer;
	}
	&__right-chevron {
		margin-bottom: -3px;
	}

	&__iframe-container {
		width: 100%;
		border-radius: 10px;
		background-color: $white;
		padding: 10px;
	}
	&__iframe {
		border-radius: 10px 10px 0 0;
	}
	&__info-container {
		padding: 20px 20px 30px 20px;
	}
	&__video-title {
		font-size: 26px;
		color: $red;
		line-height: 30px;
		@include font(Chango, 400);
	}
	&__video-tag {
		border: 1px solid $purple;
		font-size: 12px;
		color: $purple;
		display: inline-block;
		padding: 3px 10px;
		border-radius: 20px;
		margin-right: 10px;
		margin-top: 20px;
		margin-bottom: 20px;
		background-color: $white;
		cursor: pointer;
		&:hover {
			color: white;
			background-color: $red;
			border: 1px solid $white;
		}
	}
	&__description {
		color: $red;
		font-size: 16px;
	}
	&__button-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-top: 20px;
		padding-bottom: 20px;
		&:hover {
			cursor: pointer;
		}
	}
	&__cross-ico {
		color: gradient(52deg, #ff224c 26.42%, #9129ff 61.68%);
		width: 50px;
	}
	&__next-button {
		font-size: 20px;
		color: $red;
		font-weight: 400;
	}
	&__mobile-button {
		display: none;
	}
}
// effets de la card SwapVideo et swapArticle (don't touch it's magic)
.letter {
	z-index: 2;
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	max-width: 550px;
	min-height: 760px;
	padding: 24px;
	position: relative;
	width: 90%;
	border-radius: 20px;
}
.letter:before,
.letter:after {
	content: "";
	height: 98%;
	position: absolute;
	width: 100%;
	z-index: -1;
	border-radius: 20px;
}
.letter:before {
	background: #fafafa;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	left: -5px;
	top: 4px;
	transform: rotate(-2.5deg);
	border-radius: 20px;
}
.letter:after {
	background: white;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	right: -3px;
	top: 1px;
	transform: rotate(1.4deg);
	border-radius: 20px;
}

@media only screen and (max-width: 740px) {
	.letter {
		min-height: 800px;
	}
	.swapVideo {
		padding: 3% 0%;
		flex-direction: column;
		align-items: center;
		&__title {
			text-align: center;
		}
		&__text {
			width: 100%;
			text-align: center;
			padding: 0 10%;
		}
        &__video-tag {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        &__video-title {
            font-size: 20px;
        }
		&__card-container {
			width: 90%;
			margin-bottom: 0;
			margin-left: 0;
		}
		&__aside-container {
			margin-top: 3%;
		}
		&__aside-pref-button-container {
			display: none;
		}
		&__aside-button {
			display: none;
		}
		&__mobile-button {
			display: flex;
			justify-content: center;
			text-align: center;
			border: 1px solid $white;
			background-color: transparent;
			color: $white;
			border-radius: 20px;
			padding: 5px 20px;
			margin-top: 40px;
			margin-bottom: 3%;
		}
		&__right-chevron-mobile {
			margin-left: 10px;
			margin-top: 6px;
		}
	}
}
.videoContainer {
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	padding-left: 2%;
	padding-right: 2%;
}
.video {
	background-color: $white;
	display: flex;
	flex-direction: column;
	border-radius: 10px;

	&__iframe {
		width: 100%;
		height: 300px;
		border-radius: 10px;
		border: none;
	}

	&__title {
		@include font(Chango, 700);
		color: $red;
		margin-top: 2%;
		font-size: 20px;
		line-height: 23px;
	}

	&__desc {
		font-size: 13px;
		text-overflow: ellipsis;
		max-height: 100px;
		overflow: hidden;
		margin-top: 2%;
		color: $red;
	}
	&__description-container {
		padding: 20px;
		max-height: 270px;
		overflow: hidden;
	}
	&__categories-container {
		white-space: nowrap;
	}

	&__categories {
		display: inline-block;
		margin-right: 5px;
		border-radius: 20px;
		border: 1px solid $purple;
		font-size: 12px;

		color: $purple;
		padding: 3px 10px;
	}

	@media only screen and (min-width: 740px) {
		width: 29%;
	}
}

.allOnPeriods {
	background-image: url("../assets/images/background-allOnPeriod.svg");
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 400px;
	&__filter-container {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-left: 4%;
	}
	&__headerPage {
		margin: 2% 10% 0% 10%;
	}

	&__title {
		width: auto;
		display: inline-block;
		background-color: white;
		color: $purple;
	}
	&__paragraph {
		color: $purple;
		font-weight: bold;
	}
	&__header-logo-container {
		display: flex;
		justify-content: right;
		margin-right: 20%;
		margin-top: 3%;
	}
	&__header-logo {
		text-align: right;
	}
	&__videos {
		background: linear-gradient(-45deg, $red, $purple);
		height: 100%;
		padding-bottom: 3%;
	}

	&__videos-title {
		font-size: 20px;
		color: $white;
		margin-left: 2%;
	}

	&__videos-button {
		display: block;
		margin: 2% auto;
		border-radius: 20px;
		border: 1px solid white;
		background-color: transparent;
		font-size: 20px;
		padding: 5px 10px;
		color: $white;
	}
	&__videos-button-ico {
		margin-bottom: -3px;
	}
}

.resultVideos {
	background: linear-gradient(-45deg, $red, $purple);
	padding-bottom: 30px;
	&__title {
		color: $white;
		margin: 0 25px;
		font-size: 50px;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	&__title-faq {
		color: $white;
		margin: 0 25px;
		font-size: 50px;
		text-align: center;
		margin-bottom: 30px;
	}
}

.choosePref {
	&__button-choose-pref {
		background-color: $white;
		border-radius: 20px;
		color: $red;
		border: 1px solid $white;
		padding: 5px 15px;
		height: 38px;
		&:hover {
			cursor: pointer;
		}
	}
	&__right-chevron {
		margin-bottom: -3px;
	}
	&__modal-close-button {
		color: $white;
		font-size: 50px;
		position: absolute;
		top: 5px;
		right: 10px;
		transition: font-size ease 0.2s;
		&:hover {
			cursor: pointer;
			font-size: 55px;
		}
	}
	&__step1-title {
		color: $white;
		margin-top: 20px;
		text-align: center;
		line-height: 35px;
		font-size: 23px;
		@include font(Chango, 400);
	}
	&__step2-title {
		color: $white;
		line-height: 35px;
		margin-bottom: 15px;
		margin-top: 30px;
		font-size: 23px;
		@include font(Chango, 400);
	}
	&__tag-container {
		margin-top: 20px;
	}
	&__tag {
		background-color: transparent;
		color: white;
		border-radius: 20px;
		margin-left: 10px;
		margin-bottom: 10px;
		padding: 5px 10px;
		font-size: 14px;
		border: 1px solid white;
		margin-top: 10px;
		&--actif {
			padding: 5px 10px;
			background-color: white;
			color: $red;
			border-radius: 20px;
			margin-left: 10px;
			font-size: 14px;
			border: none;
		}
		&:hover {
			cursor: pointer;
		}
	}
	&__next-button-container {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	&__next-button {
		background-color: white;
		color: $red;
		padding: 5px 15px;
		border: 1px solid white;
		border-radius: 20px;
		font-size: 18px;
		&:hover {
			cursor: pointer;
		}
	}
	&__cup-img {
		position: absolute;
		bottom: 0;
		left: 10%;
		width: 10%;
	}
	&__period-question-container {
		margin-top: 30px;
	}
	&__checkbox-container {
		display: flex;
		flex-direction: column;
	}
	&__checkbox-line {
		color: white;
		input {
			background-color: transparent;
			height: 12px;
		}
		label {
			margin-left: 15px;
			font-size: 20px;
		}
		input[type="checkbox"]:checked {
			background-color: red;
		}
		input[type="checkbox"]::before {
			border: 1px solid white;
			background-color: transparent;
		}
	}
	&__select {
		border-top: none;
		border-left: none;
		border-right: none;
		color: white;
		font-size: 25px;
		background-color: transparent;
		border-bottom: 1px solid white;
		option {
			color: black;
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 740px) {
	.choosePref {
		&__cup-img {
			width: 30%;
		}
	}
}


.partners-footer {
	background-color: $white;
	padding-bottom: 50px;
	&__row-container {
		display: flex;
		align-items: center;
		margin-left: 10%;
		margin-right: 10%;
	}
	&__title {
		font-size: 40px;
		padding-left: 10%;
		font-weight: normal;
		padding-top: 40px;
	}
	&__logos-container {
		width: 70%;
	}
	&__img-container {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	&__button-container {
		width: 30%;
	}
	&__button {
		background-color: $red;
		color: $white;
		padding: 10px 15px;
		border: none;
		border-radius: 50px;
		&:hover {
			cursor: pointer;
		}
	}
	&__chevron {
		margin-bottom: -3px;
	}
}

@media only screen and (max-width: 740px) {
	.partners-footer {
		display: flex;
		flex-direction: column;
		&__title {
			font-size: 24px;
		}
		&__row-container {
			display: flex;
			flex-direction: column;
		}
		&__button-container {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 30px;
		}
	}
}
.filter {
	&__title {
		font-size: 18px;
		margin-bottom: 10px;
		color: $white;
		margin-left: 10px;
		text-shadow: 0.05em 0.05em 0.1em black;
	}
	&__button--inactif {
		cursor: pointer;
		border: 1px solid $white;
		border-radius: 20px;
		color: $white;
		padding: 5px 10px;
		background-color: transparent;
		margin-left: 10px;
		margin-bottom: 10px;
		transition: all ease 0.3s;
		text-shadow: 0.05em 0.05em 0.1em black;
	}
	&__button--actif {
		cursor: pointer;
		transition: all ease 0.3s;
		margin-left: 10px;
		border: 1px solid $white;
		border-radius: 20px;
		color: $red;
		padding: 5px 10px;
		margin-bottom: 10px;
		background-color: $white;
	}
}
.partners {
	background-image: url("../assets/images/background-purple.png");
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 400px;
	&__grid-logo {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 200px;
		margin: 3rem 10%;
		grid-gap: 2rem;
	}
	&__logo {
		position: relative;
		display: block;
		&::after {
			content: "";
			padding-top: 100%;
			display: block;
		}
	}

	&__img {
		max-width: 100%;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__header {
		margin-left: 10%;
		margin-right: 10%;
	}
	&__title {
		font-weight: normal;
		color: $purple;
		margin-top: 5%;
	}
	&__description {
		color: $purple;
		font-weight: bold;
	}
}

.home-reseaux {
	display: flex;
	flex-wrap: wrap;
	align-items: top;
	padding-left: 10%;
	padding-right: 10%;
	margin-bottom: -8px;
	&__right-container {
		margin-left: 60px;
		padding-top: 20px;
	}
	&__image-container {
		width: 200px;
	}
	&__link-container {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	&__title {
		color: $purple;
		font-size: 20px;
		@include font(Chango, 700);
		text-align: center;
	}
	&__bulle {
		display: none;
	}
}

@media only screen and (max-width: 740px) {
	.partners {
		&__grid-logo {
			grid-template-columns: repeat(2, 1fr);
			margin: 2rem 10%;
		}
	}
	.home-reseaux {
		&__title-container {
			display: none;
		}
		&__bulle {
			display: flex;
			justify-content: center;
		}
	}
}

.result {
	background-image: url("../assets/images/background-purple.png");
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 400px;
	&__header-container {
		margin: 40px 10%;
		max-width: 1000px;
	}
	&__header-title {
		color: $purple;
		display: inline-block;
		background-color: $white;
		font-size: 32px;
	}
	&__header-question {
		color: $purple;
		display: inline-block;
		background-color: $white;
		font-size: 32px;
		@include font(Chango, 400);
	}
	&__searchbar-container {
		background-image: url("../assets/images/background-footer-result.svg");
		background-repeat: no-repeat;
		background-position: top left;
		background-size: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;
		margin-top: 30px;
		padding: 0% 10%;
		img {
			width: 40%;
		}
	}
	&__searchbar-title {
		font-size: 16px;
		@include font(Chango, 400);
		color: $red;
		text-align: center;
		margin-bottom: 20px;
	}
	&__faq-container {
		background-color: $yellow;
		padding: 20px;
	}
	&__no-result {
		text-align: center;
		color: $red;
		@include font(Chango, 400);
		font-size: 16px;
	}
	&__send-question-button {
		background-color: $red;
		padding: 8px 23px;
		border-radius: 20px;
		border: none;
		color: $white;
		cursor: pointer;
	}
}

@media only screen and (max-width: 740px) {
	.result {
		&__searchbar-container {
			img {
				width: 90%;
			}
		}
	}
}

.faq-young {
	background-image: url("../assets/images/background-allQuestion.svg");
}

.QuestionUser {
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 400px;
	&__header-container {
		margin: 40px 10% 0% 10%;
		max-width: 1000px;
	}
	&__header-title {
		font-size: 32px;
		line-height: 30px;
		font-weight: 400;
		display: inline-block;
		background-color: $white;
	}
	&__header-paragraph {
		margin-top: 3%;
		margin-bottom: 5%;
		font-weight: bold;
	}
}

.questionModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&__img {
		width: 50px;
	}
	&__title {
		color: $white;
		font-size: 23px;
		text-align: center;
		margin-bottom: 20px;
		@include font(Chango, 400);
	}
	&__send-button {
		&--actif {
			border-radius: 20px;
			margin-top: 40px;
			border: none;
			color: $red;
			background-color: $white;
			padding: 5px 15px;
			transition: all ease 0.5s;
		}
		&--inactif {
			transition: all ease 0.5s;
			border-radius: 20px;
			margin-top: 40px;
			border: none;
			color: black;
			background-color: grey;
			padding: 5px 15px;
		}
	}
	&__triangle {
		width: 0;
		height: 0;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		border-top: 35px solid white;
		border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	&__textarea {
		border: none;
		border-radius: 20px;
		width: 90%;
		max-width: 500px;
		padding-left: 10px;
		padding-top: 10px;
	}
	&__remark {
		color: $white;
		font-size: 18px;
		text-align: center;
		margin-top: 20px;
		font-weight: bold;
	}
}

.thanks-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: $white;
	&__check-img {
		font-size: 50px;
	}
	&__title {
		font-size: 23px;
		font-weight: 700;
		margin-top: 5%;
		margin-bottom: 5%;
		text-align: center;
		font-style: italic;
		@include font(Chango, 400);
	}
	&__text {
		text-align: center;
	}
	&__tiktok {
		background-color: $red;
		font-size: 50px;
		padding: 8px;
		border-radius: 25px;
		&:hover {
			cursor: pointer;
		}
	}
	&__link-tiktok {
		margin-top: 5%;
	}
}

.send-question-button {
	width: 250px;
	color: $white;
	padding: 5px 0px;
	background-color: $red;
	border-radius: 20px;
	border: none;
	&:hover {
		cursor: pointer;
	}
}

.send-question {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-top: 3%;
	padding-bottom: 35px;
	&__img {
		padding: 3% 3% 1% 3%;
		width: 70%;
	}
}

.young-head {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 3%;
	&__searchbar-container {
		background-color: $yellow;
		border-radius: 10px;
		width: 70%;
		height: 450px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-evenly;
		padding-bottom: 80px;
		padding-top: 60px;
		padding-right: 20%;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
	}
	&__searchbar-form {
		display: flex;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
		// Obliger de la mettre la car le background url pour l'icone de loupe prend le dessus et cancel le color
		input {
			background-color: white;
		}
	}
	&__input {
		border-radius: 23px;
		border: 1px solid $purple;
		height: 45px;
		width: 350px;
		padding-left: 40px;
		background: url(../assets/images/SearchIco.svg) no-repeat scroll 10px
			10px;
	}
	&__send-button {
		background-color: $purple;
		color: $white;
		border: none;
		border-radius: 22px;
		margin-left: -45px;
		height: 45px;
		width: 45px;
		cursor: pointer;
	}
	&__send-button-ico {
		font-size: 30px;
		margin-top: 6px;
	}
	&__video-container {
		width: 50%;
		max-width: 600px;
		margin-left: -15%;
	}
	&__video {
		width: 100%;
		border-radius: 20px 20px 0px 0px;
	}
	&__video-desc-container {
		background-color: $white;
		margin-top: -7px;
		padding: 25px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
		border-radius: 0px 0px 20px 20px;
	}
	&__video-title {
		font-size: 23px;
		color: $red;
		line-height: 30px;
		@include font(chango, 400);
	}
	&__tag-list-container {
		display: flex;
		margin-top: 15px;
		column-gap: 10px;
	}
	&__tag {
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 20px;
		border: 1px solid $purple;
		color: $purple;
		cursor: pointer;
		background-color: $white;
		&:hover {
			color: $white;
			background-color: $red;
			border: 1px solid $white;
		}
	}

	&__img {
		width: 80%;
	}
	&__question-desktop-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 20px 50px;
	}
	&__question-desktop {
		background-color: $red;
		border-radius: 20px;
		color: $white;
		border: 0;
		padding: 5px 15px;
		margin-top: 20px;
		text-underline-offset: 2px;
		cursor: pointer;
		margin-left: 5px;
		margin-right: 5px;
	}
}

@media only screen and (max-width: 740px) {
	.young-head {
		background-image: url(../assets/images/Background-home-young.svg);
		background-repeat: no-repeat;
		background-position: 100% 0%;
		display: flex;
		flex-direction: column;
		&__searchbar-container {
			width: 100%;
			box-shadow: none;
			padding: 0;
			justify-content: center;
			background-color: transparent;
			height: auto;
		}
		&__searchbar-form {
			width: 80%;
		}
		&__input {
			width: 100%;
		}
		&__video-container {
			z-index: 0;
			width: 90%;
			margin-left: 0;
		}
		&__most-question-container {
			margin-bottom: 30px;
		}
		&__question-mobile {
			background-color: $red;
			border-radius: 20px;
			color: $white;
			border: 0;
			padding: 5px 15px;
			margin-bottom: 10px;
			margin-left: 5px;
			margin-right: 5px;
		}
		&__video-title {
			font-size: 19px;
			line-height: 23px;
		}
		&__most-question-container {
			width: 100%;
			padding-left: 5%;
			padding-right: 5%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		.choosePref__button-choose-pref {
			background-color: $red;
			color: $white;
			margin-top: 20px;
			margin-bottom: 30px;
			width: 100%;
			height: 40px;
		}
	}
}

.notation {
	&__button {
		background: $purple;
		color: $white;
		padding: 5px 15px;
		border-radius: 20px;
		border: none;
		cursor: pointer;
	}
	&__modal-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__img-question {
		width: 60%;
	}
	&__close-button {
		color: black;
		font-size: 50px;
		position: absolute;
		top: 5px;
		right: 10px;
		transition: font-size ease 0.2s;
		&:hover {
			cursor: pointer;
			font-size: 55px;
		}
	}
	&__star {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 50px;
		color: $red;
		&--inactif {
			color: #FFDCE2;
		}
	}
	&__legend-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
		color: $red;
		text-align: center;
	}
	&__question {
		margin-top: 40px;
	}
}

@media only screen and (max-width: 740px) {
	.notation {
		&__img-question {
			width: 100%;
		}
	}
}

.chatbotPopup {
	&__button {
		position: fixed;
		bottom: 40px;
		right: 50px;
		z-index: 3;
		background-color: $red;
		width: 440px;
		cursor: pointer;
		height: 80px;
		border-radius: 50px;
		border: 0px;
		box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
	&__button-img {
		width: 60px;
		height: 60px;
		background-color: $white;
		border-radius: 100%;
		box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
		animation: bounce 2s ease infinite;
	}
	@keyframes bounce {
		0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
		40% {transform: translateY(-30px);}
		60% {transform: translateY(-15px);}
	}
	&__button-text {
		width: 340px;
		color: white;
		@include font(Chango, 400);
		margin-left: 10px;
	}
	&__header {
		height: 60px;
	}
	&__logo {
		width: 300px;
		position: absolute;
		top: 20px;
		left: 50px;
	}
	&__modal-close-button {
		color: $purple;
		font-size: 60px;
		position: absolute;
		top: 20px;
		right: 20px;
		transition: font-size ease 0.2s;
		&:hover {
			cursor: pointer;
			font-size: 70px;
		}
	}
	&__iframe {
		border-radius: 20px;
		border: 0px;
		height: 80%;
		margin-top: 20px;
		box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
	}
}

@media only screen and (max-width: 740px) {
	.chatbotPopup {
		&__button {
			width: 300px;
			padding: 10px;
			right: 10px;
		}
		&__button-img {
			width: 60px;
			height: 60px;
		}
		&__button-text {
			width: 220px;
			font-size: 12px;
		}
		&__logo {
			width: 250px;
			left: 20px;
		}
	}
}